/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

var swiper4;

(function ($) {
  function getOffset() {
    var offset = 190;
    if ($(window).width() > 768) {
      offset = 80;
    }
    return offset;
  }

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function () {
        $(window).on("load", function () {
          $("body").removeClass("fade-out");
          $(".slideInAnimationElement").removeClass("slideInAnimation");

          $(window).on("beforeunload", function () {
            $("body").addClass("fade-out-anim");
          });
        });

        // $('.latest-col .latest-item-title').matchHeight();

        var append = "?autoplay=1&loop=1&background=1";
        if (typeof videos !== "undefined") {
          var random = Math.floor(Math.random() * videos.length);

          var headerCover = document.getElementsByClassName("header-cover")[0];
          headerCover.style.backgroundImage = "url(" + videos[random][1] + ")";
          headerCover.style.backgroundSize = "cover";

          document.getElementById("header-video").src =
            videos[random][0] + append;
        }

        //init animations
        AOS.init({
          duration: 600,
          disable: "mobile",
        });

        //Match Footer menu height
        $(
          ".team-members-new .team-member-block .content-section"
        ).matchHeight();

        //Match Footer menu height
        $(".footer__middle>div").matchHeight();

        if ($(window).width() >= 1200) {
          $(".nav-primary").addClass("disable");
        }

        $(".team__tabs>a, .media__tabs>a").on("click", function () {
          $(".team__tabs>a, .media__tabs>a").removeClass("active");
          var href = $(this).attr("href");
          if (
            href === "#who-we-work-with" ||
            href === "#partners" ||
            href === "#success"
          ) {
            //$('body').addClass('gray');
          } else {
            //$('body').removeClass('gray');
          }
          $(this).addClass("active");
        });

        $(window).scroll(function () {
          var scrollPercentage =
            100 * ($(this).scrollTop() / $("body").height());
          if (scrollPercentage >= 2) {
            $("header").addClass("fixed");
            $("#scroll-header").addClass("fixed");
            $(".header__content").addClass("fixed");
            if ($(window).width() >= 1200) {
              $(".nav-primary").removeClass(
                "cbp-spmenu cbp-spmenu-vertical cbp-spmenu-right"
              );
            } else {
              $(".nav-primary").addClass(
                "cbp-spmenu cbp-spmenu-vertical cbp-spmenu-right"
              );
            }
          } else {
            $("header").removeClass("fixed");
            $("#scroll-header").removeClass("fixed");
            $(".header__content").removeClass("fixed");
            $(".nav-primary").addClass(
              "cbp-spmenu cbp-spmenu-vertical cbp-spmenu-right"
            );
          }

          var winScroll =
            document.body.scrollTop || document.documentElement.scrollTop;
          var height =
            document.documentElement.scrollHeight -
            document.documentElement.clientHeight;
          var scrolled = (winScroll / height) * 100;
          var scrollerBar = document.getElementById("myBar");
          if (scrollerBar) {
            scrollerBar.style.width = scrolled + "%";
          }
        });

        function toggleMenu() {
          var $width = $(window).width();
          var nav = $(".nav-primary");

          if ($width >= 1200) {
            $(".toggle-icon").toggleClass("fa-bars");
            $(".toggle-icon").toggleClass("fa-chevron-down");
          } else {
            $(".toggle-icon").toggleClass("fa-bars");
            $(".toggle-icon").toggleClass("fa-times");
            $(".btn-toggle").toggleClass("cbp-spmenu-push-toleft");
            $(".nav-primary").toggleClass("cbp-spmenu-open");
          }
        }
        //Close slide menu if click anyway on page
        $("body").on("click", function () {
          if ($(".nav-primary").hasClass("cbp-spmenu-open")) {
            toggleMenu();
          }
        });

        //Toggle slide menu
        $("#nav-toggle").on("click", function (e) {
          toggleMenu();
          e.stopPropagation();
        });

        var swiper = new Swiper(".swiper-container-latest", {
          slidesPerView: 4,
          spaceBetween: 5,
          slidesOffsetAfter: 500,
          breakpoints: {
            1350: {
              slidesPerView: 2.5,
              spaceBetween: 5,
              slidesOffsetAfter: 550,
            },
            700: {
              slidesPerView: 1,
              spaceBetween: 5,
              slidesOffsetAfter: 650,
            },
            500: {
              slidesPerView: 1,
              spaceBetween: 5,
              slidesOffsetAfter: 0,
            },
          },
        });

        var swiper2 = new Swiper(".swiper-container-success-stories", {
          slidesPerView: 3.5,
          slidesOffsetAfter: 1400,
          spaceBetween: 20,
          breakpoints: {
            1500: {
              slidesPerView: 2.5,
              spaceBetween: 20,
              slidesOffsetAfter: 800,
            },
            1200: {
              slidesPerView: 1.5,
              spaceBetween: 20,
              slidesOffsetAfter: 500,
            },
            800: {
              slidesPerView: 1,
              spaceBetween: 20,
              slidesOffsetAfter: 0,
            },
          },
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
        });

        var swiper3 = new Swiper(".swiper-container-other-team", {
          slidesPerView: 7,
          slidesOffsetAfter: 0,
          loop: true,
          spaceBetween: 20,
          breakpoints: {
            1500: {
              slidesPerView: 3.5,
              spaceBetween: 20,
            },
            767: {
              slidesPerView: 1.5,
              spaceBetween: 20,
              slidesOffsetAfter: 0,
            },
          },
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        });

        swiper4 = new Swiper(".swiper-container-latest--logo-tile", {
          slidesPerView: 5.5,
          spaceBetween: 5,
          slidesOffsetAfter: 500,
          breakpoints: {
            1350: {
              slidesPerView: 2.5,
              spaceBetween: 5,
              slidesOffsetAfter: 550,
            },
            700: {
              slidesPerView: 1.5,
              spaceBetween: 5,
              slidesOffsetAfter: 650,
            },
            500: {
              slidesPerView: 1,
              spaceBetween: 5,
              slidesOffsetAfter: 0,
            },
          },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        });

        //Enable fontawesome 5 js to check pseudo elements and replace with svg
        //window.FontAwesomeConfig.searchPseudoElements = true;

        $(".match-grid").matchHeight();

        $(".service-item a").on("click", function (e) {
          var serviceImage = $(this).data("service-image");
          var serviceColor = $(this).data("service-color");
          var serviceContent = $(this).data("service-content");
          var serviceTitle = $(this).data("service-title");

          e.preventDefault();
          $(".banner .tagline-panel.dark").remove();
          $(".banner.large").css("height", "160px");

          /* Contact Changes */
          $(".info-block-top").show();
          $(".info-block-top h1").text(serviceTitle);
          $(".info-block-top h1").css("color", serviceColor);
          $(".info-block-top .content-side .main-content").html(serviceContent);
          if (serviceImage) {
            $(".info-block-top img").attr("src", serviceImage);
          }

          $(".banner.large").css("height", "0");
          $(".header__content").addClass("service-fix");
          $(".service-holder").addClass("activated");
          $("html, body").animate({
            scrollTop: 0,
          });
        });

        var currentItem = null;
        $(".search-field").on("click", function (e) {
          if ($("#toolbar .search-form label").hasClass("selected")) {
            $("#toolbar .search-form label").removeClass("selected");
            currentItem = null;
          } else {
            $("#toolbar .search-form label.selected").removeClass("selected");
            currentItem = $("#toolbar .search-form label").addClass("selected");
          }
          e.stopPropagation();
        });

        $("#toolbar .search-form label.selected").removeClass("selected");

        if ($(".child-page-item").hasClass("active")) {
          var activeChildClasses = $(".child-page-item.active")
            .prop("className")
            .split(" ");
          var activeElement = "." + activeChildClasses.join(".");
          var nonActiveString = activeElement.replace(".active", "");

          $(".new-navigation li").removeClass("active");
          $(nonActiveString).addClass("active");
        }

        $(".scrollNav").on("click", function (event) {
          if (this.hash !== "") {
            event.preventDefault();
            const hash = this.hash;
            $("html, body").animate(
              {
                scrollTop: $(hash).offset().top,
              },
              500,
              function () {
                if (hash !== "#Top") {
                  window.location.hash = hash;
                }
              }
            );
          }
        });
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
        $(".latest-panel__content--small").matchHeight();

        $(".latest-item-title").each(function (_index, val) {
          $(val).ellipsis({
            row: 2,
          });
        });

        $(".event-ellipsis").each(function (_index, val) {
          $(val).ellipsis({
            row: 2,
          });
        });

        $(".latest-item-title").matchHeight();
        $(".event-ellipsis").matchHeight();

        // Ellipsis({
        //   className: ".latest-item-title",
        //   lines: 2,
        // });

        // Ellipsis({
        //   className: ".event-ellipsis",
        //   lines: 2,
        // });

        var loopCount = 0;
        var statIds = $(".stats-grouping")
          .map(function () {
            return this.id;
          })
          .get();

        function swapStats() {
          $("#" + statIds[loopCount]).fadeOut("slow", function () {
            loopCount++;
            if (loopCount === statIds.length) {
              loopCount = 0;
            }
            $("#" + statIds[loopCount]).fadeIn("slow");
          });
          setTimeout(swapStats, 7000);
        }

        var leftMap = document.getElementById("map-right");
        if (leftMap) {
          var mapLeft;
          mapLeft = new google.maps.Map(document.getElementById("map-right"), {
            center: { lat: 55.9538262, lng: -3.216284 },
            zoom: 16,
            disableDefaultUI: true,
            styles: [
              {
                elementType: "geometry",
                stylers: [
                  {
                    color: "#212121",
                  },
                ],
              },
              {
                elementType: "labels.icon",
                stylers: [
                  {
                    visibility: "off",
                  },
                ],
              },
              {
                elementType: "labels.text.fill",
                stylers: [
                  {
                    color: "#757575",
                  },
                ],
              },
              {
                elementType: "labels.text.stroke",
                stylers: [
                  {
                    color: "#212121",
                  },
                ],
              },
              {
                featureType: "administrative",
                elementType: "geometry",
                stylers: [
                  {
                    color: "#757575",
                  },
                ],
              },
              {
                featureType: "administrative.country",
                elementType: "labels.text.fill",
                stylers: [
                  {
                    color: "#9e9e9e",
                  },
                ],
              },
              {
                featureType: "administrative.locality",
                elementType: "labels.text.fill",
                stylers: [
                  {
                    color: "#bdbdbd",
                  },
                ],
              },
              {
                featureType: "poi",
                elementType: "labels.text.fill",
                stylers: [
                  {
                    color: "#757575",
                  },
                ],
              },
              {
                featureType: "poi.business",
                stylers: [
                  {
                    visibility: "off",
                  },
                ],
              },
              {
                featureType: "poi.park",
                elementType: "geometry",
                stylers: [
                  {
                    color: "#181818",
                  },
                ],
              },
              {
                featureType: "poi.park",
                elementType: "labels.text",
                stylers: [
                  {
                    visibility: "off",
                  },
                ],
              },
              {
                featureType: "poi.park",
                elementType: "labels.text.fill",
                stylers: [
                  {
                    color: "#616161",
                  },
                ],
              },
              {
                featureType: "poi.park",
                elementType: "labels.text.stroke",
                stylers: [
                  {
                    color: "#1b1b1b",
                  },
                ],
              },
              {
                featureType: "road",
                elementType: "geometry.fill",
                stylers: [
                  {
                    color: "#2c2c2c",
                  },
                ],
              },
              {
                featureType: "road",
                elementType: "labels.text.fill",
                stylers: [
                  {
                    color: "#8a8a8a",
                  },
                ],
              },
              {
                featureType: "road.arterial",
                elementType: "geometry",
                stylers: [
                  {
                    color: "#373737",
                  },
                ],
              },
              {
                featureType: "road.highway",
                elementType: "geometry",
                stylers: [
                  {
                    color: "#3c3c3c",
                  },
                ],
              },
              {
                featureType: "road.highway.controlled_access",
                elementType: "geometry",
                stylers: [
                  {
                    color: "#4e4e4e",
                  },
                ],
              },
              {
                featureType: "road.local",
                elementType: "labels.text.fill",
                stylers: [
                  {
                    color: "#616161",
                  },
                ],
              },
              {
                featureType: "transit",
                elementType: "labels.text.fill",
                stylers: [
                  {
                    color: "#757575",
                  },
                ],
              },
              {
                featureType: "water",
                elementType: "geometry",
                stylers: [
                  {
                    color: "#000000",
                  },
                ],
              },
              {
                featureType: "water",
                elementType: "labels.text.fill",
                stylers: [
                  {
                    color: "#3d3d3d",
                  },
                ],
              },
            ],
          });
          var marker = new google.maps.Marker({
            position: { lat: 55.9508262, lng: -3.216284 },
            icon:
              "https://d1ssu070pg2v9i.cloudfront.net/pex/gneiss_2019/2019/12/17160419/map-marker.png",
            map: mapLeft,
          });

          marker.addListener("click", function () {
            window.open($("#directions-edinburgh").attr("href"), "_blank");
          });
        }

        var rightMap = document.getElementById("map-left");
        if (rightMap) {
          var mapRight;
          mapRight = new google.maps.Map(document.getElementById("map-left"), {
            center: { lat: 51.5162016, lng: -0.1567000 },
            zoom: 16,
            disableDefaultUI: true,
            styles: [
              {
                elementType: "geometry",
                stylers: [
                  {
                    color: "#212121",
                  },
                ],
              },
              {
                elementType: "labels.icon",
                stylers: [
                  {
                    visibility: "off",
                  },
                ],
              },
              {
                elementType: "labels.text.fill",
                stylers: [
                  {
                    color: "#757575",
                  },
                ],
              },
              {
                elementType: "labels.text.stroke",
                stylers: [
                  {
                    color: "#212121",
                  },
                ],
              },
              {
                featureType: "administrative",
                elementType: "geometry",
                stylers: [
                  {
                    color: "#757575",
                  },
                ],
              },
              {
                featureType: "administrative.country",
                elementType: "labels.text.fill",
                stylers: [
                  {
                    color: "#9e9e9e",
                  },
                ],
              },
              {
                featureType: "administrative.locality",
                elementType: "labels.text.fill",
                stylers: [
                  {
                    color: "#bdbdbd",
                  },
                ],
              },
              {
                featureType: "poi",
                elementType: "labels.text.fill",
                stylers: [
                  {
                    color: "#757575",
                  },
                ],
              },
              {
                featureType: "poi.business",
                stylers: [
                  {
                    visibility: "off",
                  },
                ],
              },
              {
                featureType: "poi.park",
                elementType: "geometry",
                stylers: [
                  {
                    color: "#181818",
                  },
                ],
              },
              {
                featureType: "poi.park",
                elementType: "labels.text",
                stylers: [
                  {
                    visibility: "off",
                  },
                ],
              },
              {
                featureType: "poi.park",
                elementType: "labels.text.fill",
                stylers: [
                  {
                    color: "#616161",
                  },
                ],
              },
              {
                featureType: "poi.park",
                elementType: "labels.text.stroke",
                stylers: [
                  {
                    color: "#1b1b1b",
                  },
                ],
              },
              {
                featureType: "road",
                elementType: "geometry.fill",
                stylers: [
                  {
                    color: "#2c2c2c",
                  },
                ],
              },
              {
                featureType: "road",
                elementType: "labels.text.fill",
                stylers: [
                  {
                    color: "#8a8a8a",
                  },
                ],
              },
              {
                featureType: "road.arterial",
                elementType: "geometry",
                stylers: [
                  {
                    color: "#373737",
                  },
                ],
              },
              {
                featureType: "road.highway",
                elementType: "geometry",
                stylers: [
                  {
                    color: "#3c3c3c",
                  },
                ],
              },
              {
                featureType: "road.highway.controlled_access",
                elementType: "geometry",
                stylers: [
                  {
                    color: "#4e4e4e",
                  },
                ],
              },
              {
                featureType: "road.local",
                elementType: "labels.text.fill",
                stylers: [
                  {
                    color: "#616161",
                  },
                ],
              },
              {
                featureType: "transit",
                elementType: "labels.text.fill",
                stylers: [
                  {
                    color: "#757575",
                  },
                ],
              },
              {
                featureType: "water",
                elementType: "geometry",
                stylers: [
                  {
                    color: "#000000",
                  },
                ],
              },
              {
                featureType: "water",
                elementType: "labels.text.fill",
                stylers: [
                  {
                    color: "#3d3d3d",
                  },
                ],
              },
            ],
          });
          var markerRight = new google.maps.Marker({
            position: { lat: 51.5132016, lng: -0.1567000 },
            icon:
              "https://d1ssu070pg2v9i.cloudfront.net/pex/gneiss_2019/2019/12/17160419/map-marker.png",
            map: mapRight,
          });

          markerRight.addListener("click", function (e) {
            window.open($("#directions-london").attr("href"), "_blank");
          });
        }


        setTimeout(swapStats, 10000);
      },
    },
    // Contact page
    contact: {
      finalize: function () {
        // Map
        var mapLeft;
        mapLeft = new google.maps.Map(document.getElementById("map-right"), {
          center: { lat: 55.9538262, lng: -3.216284 },
          zoom: 16,
          disableDefaultUI: true,
          styles: [
            {
              elementType: "geometry",
              stylers: [
                {
                  color: "#212121",
                },
              ],
            },
            {
              elementType: "labels.icon",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#757575",
                },
              ],
            },
            {
              elementType: "labels.text.stroke",
              stylers: [
                {
                  color: "#212121",
                },
              ],
            },
            {
              featureType: "administrative",
              elementType: "geometry",
              stylers: [
                {
                  color: "#757575",
                },
              ],
            },
            {
              featureType: "administrative.country",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#9e9e9e",
                },
              ],
            },
            {
              featureType: "administrative.locality",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#bdbdbd",
                },
              ],
            },
            {
              featureType: "poi",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#757575",
                },
              ],
            },
            {
              featureType: "poi.business",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "poi.park",
              elementType: "geometry",
              stylers: [
                {
                  color: "#181818",
                },
              ],
            },
            {
              featureType: "poi.park",
              elementType: "labels.text",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "poi.park",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#616161",
                },
              ],
            },
            {
              featureType: "poi.park",
              elementType: "labels.text.stroke",
              stylers: [
                {
                  color: "#1b1b1b",
                },
              ],
            },
            {
              featureType: "road",
              elementType: "geometry.fill",
              stylers: [
                {
                  color: "#2c2c2c",
                },
              ],
            },
            {
              featureType: "road",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#8a8a8a",
                },
              ],
            },
            {
              featureType: "road.arterial",
              elementType: "geometry",
              stylers: [
                {
                  color: "#373737",
                },
              ],
            },
            {
              featureType: "road.highway",
              elementType: "geometry",
              stylers: [
                {
                  color: "#3c3c3c",
                },
              ],
            },
            {
              featureType: "road.highway.controlled_access",
              elementType: "geometry",
              stylers: [
                {
                  color: "#4e4e4e",
                },
              ],
            },
            {
              featureType: "road.local",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#616161",
                },
              ],
            },
            {
              featureType: "transit",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#757575",
                },
              ],
            },
            {
              featureType: "water",
              elementType: "geometry",
              stylers: [
                {
                  color: "#000000",
                },
              ],
            },
            {
              featureType: "water",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#3d3d3d",
                },
              ],
            },
          ],
        });
        var marker = new google.maps.Marker({
          position: { lat: 51.5132016, lng: -0.1567000 },
          icon:
            "https://d1ssu070pg2v9i.cloudfront.net/pex/gneiss_2019/2019/12/17160419/map-marker.png",
          map: mapLeft,
        });

        marker.addListener("click", function () {
          window.open($("#directions-edinburgh").attr("href"), "_blank");
        });

        var mapRight;
        mapRight = new google.maps.Map(document.getElementById("map-left"), {
          center: { lat: 51.5162016, lng: -0.1567000 },
          zoom: 16,
          disableDefaultUI: true,
          styles: [
            {
              elementType: "geometry",
              stylers: [
                {
                  color: "#212121",
                },
              ],
            },
            {
              elementType: "labels.icon",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#757575",
                },
              ],
            },
            {
              elementType: "labels.text.stroke",
              stylers: [
                {
                  color: "#212121",
                },
              ],
            },
            {
              featureType: "administrative",
              elementType: "geometry",
              stylers: [
                {
                  color: "#757575",
                },
              ],
            },
            {
              featureType: "administrative.country",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#9e9e9e",
                },
              ],
            },
            {
              featureType: "administrative.locality",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#bdbdbd",
                },
              ],
            },
            {
              featureType: "poi",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#757575",
                },
              ],
            },
            {
              featureType: "poi.business",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "poi.park",
              elementType: "geometry",
              stylers: [
                {
                  color: "#181818",
                },
              ],
            },
            {
              featureType: "poi.park",
              elementType: "labels.text",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "poi.park",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#616161",
                },
              ],
            },
            {
              featureType: "poi.park",
              elementType: "labels.text.stroke",
              stylers: [
                {
                  color: "#1b1b1b",
                },
              ],
            },
            {
              featureType: "road",
              elementType: "geometry.fill",
              stylers: [
                {
                  color: "#2c2c2c",
                },
              ],
            },
            {
              featureType: "road",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#8a8a8a",
                },
              ],
            },
            {
              featureType: "road.arterial",
              elementType: "geometry",
              stylers: [
                {
                  color: "#373737",
                },
              ],
            },
            {
              featureType: "road.highway",
              elementType: "geometry",
              stylers: [
                {
                  color: "#3c3c3c",
                },
              ],
            },
            {
              featureType: "road.highway.controlled_access",
              elementType: "geometry",
              stylers: [
                {
                  color: "#4e4e4e",
                },
              ],
            },
            {
              featureType: "road.local",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#616161",
                },
              ],
            },
            {
              featureType: "transit",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#757575",
                },
              ],
            },
            {
              featureType: "water",
              elementType: "geometry",
              stylers: [
                {
                  color: "#000000",
                },
              ],
            },
            {
              featureType: "water",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#3d3d3d",
                },
              ],
            },
          ],
        });
        var markerRight = new google.maps.Marker({
          position: { lat: 51.5132016, lng: -0.1567000 },
          icon:
            "https://d1ssu070pg2v9i.cloudfront.net/pex/gneiss_2019/2019/12/17160419/map-marker.png",
          map: mapRight,
        });

        markerRight.addListener("click", function (e) {
          window.open($("#directions-london").attr("href"), "_blank");
        });
      },
    },
    // Home page
    home: {
      init: function () {
        $(".latest-panel--small").matchHeight();

        $(".scrollNav, .explore-gneiss-button").on("click", function () {
          $("html, body").animate(
            {
              scrollTop: $("#Services").offset().top,
            },
            1000
          );
        });
      },
      finalize: function () {
        var wait1 = true;
        var wait2 = true;
        if ($("#brent").length > 0) {
          wait1 = true;
          $.getJSON(
            "https://www.quandl.com/api/v1/datasets/CHRIS/ICE_B1.json?api_key=h-A4tbiPjdazFus81zkm"
          )
            .done(function (data) {
              // $('#oil-date').html(data.data[0][0]);
              $("#brent").attr("data-count", data.data[0][6]);
            })
            .fail(function () {
              console.log("Failed to get oil price");
            })
            .always(function () {
              wait1 = false;
            });
        } else {
          wait1 = false;
        }

        if ($("#wti").length > 0) {
          wait2 = true;
          $.getJSON(
            "https://www.quandl.com/api/v1/datasets/CHRIS/ICE_T1.json?api_key=h-A4tbiPjdazFus81zkm"
          )
            .done(function (data) {
              // $('#oil-date').html(data.data[0][0]);
              $("#wti").attr("data-count", data.data[0][6]);
            })
            .fail(function () {
              console.log("Failed to get oil price");
            })
            .always(function () {
              wait2 = false;
            });
        } else {
          wait2 = false;
        }
      },
    },
    team: {
      init: function () {
        function teamModal(panel) {
          $(".modal-title").html($(panel).data("title"));
          $(".modal-body > #profile").html(
            $(".team-description", panel).html()
          );
          $(".modal-body > #role").html($("#team-role", panel).html());
          $("#team-modal").modal("toggle");
        }

        $(".info-panel").on("click", function () {
          if ($(window).width() < 992) {
            teamModal(this);
          }
        });

        //code for category dropdown
        $("#categorySelector").change(function () {
          var categoryDropdown = document.getElementById("categorySelector");
          var category =
            categoryDropdown.options[categoryDropdown.selectedIndex].value;
          if (category === "advisors") {
            $(".advisors").css("display", "block");
            $(".leadership").css("display", "none");
            $(".no-category").css("display", "none");
          } else if (category === "leadership") {
            $(".advisors").css("display", "none");
            $(".leadership").css("display", "block");
            $(".no-category").css("display", "none");
          } else if (category === "all") {
            $(".no-category").css("display", "block");
            $(".leadership").css("display", "block");
            $(".advisors").css("display", "block");
          }
        });
      },
    },
    services: {
      init: function () {
        if (window.location.hash) {
          $("html, body").animate(
            {
              scrollTop: $(window.location.hash).offset().top - getOffset(),
            },
            500
          );
        }

        $(".split").matchHeight();
        //Scrolltofix the side navigation with offset matching footer location
        $("#scrollNav").scrollToFixed({
          marginTop: function () {
            var margin = 110;
            return margin;
          },
          limit: $("footer").offset().top - $("#scrollNav").outerHeight() - 58,
        });
      },
      finalize: function () {
        //Enable scroll spy on body
        $("body").scrollspy({
          target: "#scrollSpy",
          offset: getOffset() + 1,
        });

        $('#scrollSpy ul li a[href^="#"]').on("click touchstart", function (
          event
        ) {
          if (this.hash !== "") {
            event.preventDefault();
            var hash = this.hash;
            $("html, body").animate(
              {
                scrollTop: $(hash).offset().top - getOffset(),
              },
              900
            );
          }
        });
      },
    },
    market: {
      init: function () {
        // $('.grid-panels').masonry({
        //     itemSelector: '.grid-item', // use a separate class for itemSelector, other than .col-
        //     percentPosition: true,
        //     columnWidth: 200,
        //     gutter: 10
        // });
      },
      finalize: function () {
        var $tombstones = $(".tombstone");
        if ($tombstones.length !== 0) {
          var mixer = mixitup(".tombstone-container", {
            animation: {
              duration: 600,
              nudge: true,
              reverseOut: false,
              effects: "fade translateZ(-100px)",
            },
            load: {
              filter: ".financial_adviser",
            },
          });
        }

        $(".tombstone").matchHeight();

        // var msnry = new Masonry( grid, {
        //     itemSelector: '.grid-item',
        //     columnWidth: '.grid-sizer',
        //     percentPosition: true
        // });

        // $('.grid-panels').masonry({
        //     itemSelector: '.grid-item', // use a separate class for itemSelector, other than .col-
        //     columnWidth: '.grid-sizer',
        //     percentPosition: true
        // });
      },
    },
    search: {
      init: function () {},
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, "_").split(/\s+/), function (
        i,
        classnm
      ) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, "finalize");
      });

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    },
  };

  function hoverVideo(i) {
    $(".thevideo")[i].play();
  }

  function hideVideo(i) {
    $(".thevideo")[i].pause();
  }

  $(document).ready(function () {
    $(".video").each(function (i, obj) {
      $(this).on("mouseover", function () {
        hoverVideo(i);
      });
      $(this).on("mouseout", function () {
        hideVideo(i);
      });
    });
  });

  var extend = function (a, b) {
    for (var key in b) {
      if (b.hasOwnProperty(key)) {
        a[key] = b[key];
      }
    }
    return a;
  };

  var timeout;
  $(".wrap").mousemove(function (e) {
    if (timeout) clearTimeout(timeout);
    setTimeout(callParallax.bind(null, e), 200);
  });

  function callParallax(e) {
    parallaxIt(e, ".move-away", -120);
  }

  function parallaxIt(e, target, movement) {
    var $this = $(".wrap");
    var relX = e.pageX - $this.offset().left;
    var relY = e.pageY - $this.offset().top;

    TweenMax.to(target, 1, {
      x: ((relX - $this.width() / 2) / $this.width()) * movement,
      y: ((relY - $this.height() / 2) / $this.height()) * movement,
      ease: Power2.easeOut,
    });
  }

  $(window).scroll(function () {
    var element1 = $(".gneissAnimateIcon1");
    if (element1.length) {
      var top_of_element = element1.offset().top;
      var bottom_of_element = element1.offset().top + element1.outerHeight();
    }
    var element2 = $(".gneissAnimateIcon7");
    if (element2.length) {
      var top_of_element2 = element2.offset().top;
      var bottom_of_element2 = element2.offset().top + element2.outerHeight();
    }

    var top_of_screen = $(window).scrollTop();
    var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();

    bottom_of_screen -= 110;
    for (i = 1; i <= 7; i++) {
      if ($(".gneissAnimateIcon" + i)[0]) {
        if (
          (bottom_of_screen > top_of_element &&
            top_of_screen < bottom_of_element) ||
          (bottom_of_screen > top_of_element2 &&
            top_of_screen < bottom_of_element2)
        ) {
          // the element is visible, do something
          $(".gneissAnimateIcon" + i)
            .delay(1400)
            .queue(function () {
              var element = $(this);
              var animation = bodymovin.loadAnimation({
                container: element[0],
                renderer: "svg",
                loop: false,
                autoplay: true,
                path: element.data("icon"),
              });
              $(this).dequeue();
            });
          $(".gneissAnimateIcon" + i).removeClass("gneissAnimateIcon" + i);
        }
      }
    }
  });

  // $('.description p:nth-child(2)').each(function(){
  //   var me = $(this);
  //   me.html( me.text().replace(/(^\w+)/,'<strong>$1</strong>') );
  // });

  // Load Events
  $(document).ready(UTIL.loadEvents);

  // Close Covid Banner
  $(document).ready(function () {
    var values = [];

    $('.filter-checkbox').on('change', function(e) {
        var value = $(this).next("label").text().toString().trim();
        var status = e.target.checked;
        if (values.includes(value) && status == false ) {
            values = values.filter(function(item) {
                return item !== value;
            });
        }else {
            values.push(value);
        }
		
		//check if values has 2016-2019
		if( values.includes( '2016-2019' ) )
		{
			if( ! values.includes( '2016' ) && ! values.includes( '2017' ) && ! values.includes( '2018' ) && ! values.includes( '2019' ) )
			{
				//values don't exist, lets add them
				values.push('2016');
				values.push('2017');
				values.push('2018');
				values.push('2019');
			}
		}
		else
		{
			//not 2016-2019 so remove the values
			if( values.includes( '2016' ) && values.includes( '2017' ) && values.includes( '2018' ) && values.includes( '2019' ) )
			{
				values = values.filter(function(item) 
				{
					return item !== '2016';
				});
				values = values.filter(function(item) 
				{
					return item !== '2017';
				});
				values = values.filter(function(item) 
				{
					return item !== '2018';
				});
				values = values.filter(function(item) 
				{
					return item !== '2019';
				});
			}
		}

        $('.filtered-slides').each(
            function() {
                if (values.includes($(this).data('status').toString())) {
                    $(this).show();
                } else if (values.length === 0 || values.includes('All')) {
                    $(this).show();
                } else {
                    $(this).hide();
                }
            }
        );
        swiper4.update();
    });
  



    $("#covid-close").on("click", function (e) {
      e.preventDefault();
      $(".covid-banner").slideUp();

      $.ajax({
        url: ajaxurl,
        method: "GET",
        data: {
          action: "closeBanner",
        },
      })
        .done(function (data) {
          console.log("Closed Covid Banner");
        })
        .error(function (data) {
          console.log(data);
        });
    });
  });
})(jQuery); // Fully reference jQuery after this point.
